<template>
  <main>
    <img
      class="wrongBg"
      :class="{ hidden: !wrong }"
      src="./assets/images/wrongbg.png"
    />

    <div class="container">
      <div class="content">
        <img
          v-if="stage == 1"
          class="zadejte"
          src="./assets/images/zadejte.png"
          alt=""
        />
        <img
          v-if="stage == 1"
          class="sixpalces"
          src="./assets/images/kod.png"
          alt=""
        />
        <img
          v-if="stage == 1"
          class="vita"
          src="./assets/images/vita.png"
          alt=""
        />
        <img
          v-if="stage == 2"
          class="congrats"
          src="./assets/images/congrats.png"
          alt=""
        />
        <img
          v-if="stage == 2"
          class="sleva"
          src="./assets/images/sleva.png"
          alt=""
        />
        <img
          :class="{ hidden: wrong }"
          class="arrow"
          src="./assets/images/arrow.png"
          alt=""
        />
        <img
          :class="{ hidden: !wrong }"
          class="arrow"
          src="./assets/images/arrowWrong.png"
          alt=""
        />
        <form
          class="key-container"
          v-if="stage == 1"
        >
          <div
            v-for="(key, index) in 6"
            :key="index"
            class="key-wrapper"
          >
            <img
              v-if="index % 2 == 1 || index == 0"
              class="spacer"
              src="./assets/images/spacer.png"
              alt=""
            />
            <div
              v-if="index % 2 == 0 && index != 0"
              class="spacer"
            ></div>
            <div class="key">
              <img
                class="bg-key"
                src="./assets/images/inputWrong.png"
                alt=""
                :class="{ hidden: !wrong }"
              />
              <img
                class="bg-key"
                src="./assets/images/input.png"
                alt=""
                :class="{ hidden: wrong }"
              />
              <input
                type="text"
                maxlength="1"
                placeholder="0"
                class="key-input"
                pattern="[0-9]*"
                inputmode="numeric"
                @keyup="focusNext"
                @keydown="checkNumber"
                @keydown.backspace="focusBefore"
                @focus="clear"
              />
            </div>
            <div
              v-if="index % 2 == 0"
              class="spacer"
            ></div>
            <img
              v-if="index % 2 == 1"
              class="spacer"
              src="./assets/images/spacer.png"
              alt=""
            />
          </div>
        </form>
        <img
          v-if="stage == 2"
          class="newprice"
          src="./assets/images/newprice.png"
          alt=""
        />
        <img
          v-if="stage == 1"
          @click="evaluate"
          class="confirm"
          src="./assets/images/submit.png"
          alt=""
        />
        <img
          v-if="stage == 1"
          class="price"
          src="./assets/images/price.png"
          alt=""
        />
        <a
          v-if="stage == 2"
          :href="link"
          class="take"
        >
          <img
            src="./assets/images/take.png"
            alt=""
          />
        </a>
        <div
          class="coin-container"
          :class="{ stage2: stage == 2 }"
        >
          <div class="coin1-container">
            <img
              class="flare"
              :class="{ flare1: stage == 1, flare12: stage == 2 }"
              src="./assets/images/flare.png"
              alt=""
            />
            <img
              class="coin coin1"
              src="./assets/images/coin1.png"
              alt=""
            />
          </div>
          <div
            class="coin2-container"
            v-if="stage == 1"
          >
            <img
              class="flare"
              :class="{ flare2: stage == 1, flare22: stage == 2 }"
              src="./assets/images/flare.png"
              alt=""
            />
            <img
              class="coin coin2"
              src="./assets/images/coin2.png"
              alt=""
            />
          </div>
          <div
            v-if="stage == 2"
            class="new-container"
          >
            <img
              class="new"
              src="./assets/images/new.png"
              alt=""
            />
          </div>
        </div>
        <img
          v-if="wrong"
          class="wrongTable"
          src="./assets/images/wrong.png"
          alt=""
        />
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from "vue";

const video = ref(null);
const key = ref("");
const stage = ref(1);
const wrong = ref(false);
const linkObj = ref({
  194949:
    "https://www.casademonede.ro/gloria-regatului-romaniei-cadou-2?utm_source=HFDMRKING092024LESSACTIVECoroanaRegala&utm_medium=DIRECTMAILING&utm_campaign=kolekcemesice092024&utm_term=194949",
  194950:
    "https://www.casademonede.ro/gloria-regatului-romaniei-cadou-2?utm_source=HFEM1RKING092024LESSACTIVEoffer&utm_medium=EMAILING&utm_campaign=kolekcemesice092024&utm_term=194950",
});
const link = ref("");

onMounted(() => {
  window.addEventListener("keydown", (e) => {
    if (e.key == "Enter" && stage.value == 1 && !wrong.value) {
      evaluate();
    }
  });
});

const checkNumber = (event) => {
  if (event.key.length === 1 && isNaN(Number(event.key))) {
    event.preventDefault();
  }
};
const focusBefore = (e) => {
  const inputs = Array.from(e.target.form.querySelectorAll("input"));
  const index = inputs.indexOf(e.target);
  if (inputs[index].value.length == 0 && index != 0) {
    inputs[index - 1].focus();
  }
};
const focusNext = (e) => {
  const inputs = Array.from(e.target.form.querySelectorAll("input"));
  const index = inputs.indexOf(e.target);

  if (inputs[index].value.length == 0) {
    return;
  }

  if (index < inputs.length - 1) {
    inputs[index + 1].focus();
  }
};
const evaluate = (e) => {
  if (stage.value == 1 && !wrong.value) {
    const inputs = Array.from(document.querySelectorAll("input"));

    key.value = "";

    inputs.forEach((input) => {
      key.value += input.value;
    });

    console.log(Object.keys(linkObj.value).includes(key.value));
    if (Object.keys(linkObj.value).includes(key.value)) {
      stage.value = 2;
      link.value = linkObj.value[key.value];

      video.value.currentTime = 1;
      video.value.play();
    } else {
      wrong.value = true;
      setTimeout(() => {
        let inputs = Array.from(document.querySelectorAll(".key-input"));
        inputs.forEach((input) => {
          input.value = "";
        });
        wrong.value = false;
        inputs[0].focus();
      }, 1500);
    }
  }
};
</script>
